import { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { Typography } from '../../MaterialUiComponents';
import SecurityApi from '../../api/security';
import { SmallPackageInformation } from '../../components';
import { AircraftInHangar } from '../../types/security';
import StaffApi from '../../api/staffmanagement';
import { StaffManagementBays } from '../../types/staffManagement';

function HangarOverview() {
  const [hangarOverview, setHangarOverview] = useState(Array<AircraftInHangar>());
  const [staffManagementBays, setStaffManagementBays] = useState<StaffManagementBays[]>([]);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    SecurityApi.AircraftInHangar().then(res => {
      setHangarOverview(res.data);
    });
    StaffApi.GetAllStaffManagement().then(res => {
      setStaffManagementBays(res.data);
    });
  }

  const getSupervisorNames = (currentBay: string): string[] =>
    staffManagementBays.reduce<string[]>(
      (acc, bay) =>
        bay.bay.toLowerCase() === currentBay.toLowerCase()
          ? acc.concat(
            (bay.areas.find(area => area.area.toUpperCase().includes("SUPERVISORS"))?.employyes_in_group || []).map(emp => emp.employee_name)
          )
          : acc,
      []
    );

  return (
    <Grid  >
      <Typography variant={'h1'} align={'center'} text={"Hangar Overview"} />
      <Grid container direction="row" justifyContent="center" marginTop={2}>
        <Grid md={6} xs={12} item container direction="column" >
          {hangarOverview.map((row, idx) => (
            <Grid key={idx} marginBottom={idx > 0 ? 2 : 0}  >
              {idx % 2 === 0 &&
                <Paper style={{ margin: '2px' }}>
                  <SmallPackageInformation
                    packageInformation={row}
                    supervisors={getSupervisorNames(row.bay_name)}
                  />
                </Paper>
              }
            </Grid>
          ))
          }
        </Grid>
        <Grid md={6} xs={12} item container direction="column" >
          {hangarOverview.map((row, idx) => (
            <Grid key={idx} marginBottom={idx > 0 ? 2 : 0}>
              {idx % 2 !== 0 &&
                <Paper style={{ margin: '2px' }}>
                  <SmallPackageInformation 
                    packageInformation={row} 
                    supervisors={getSupervisorNames(row.bay_name)}
                  />
                </Paper>
              }
            </Grid>
          ))
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HangarOverview;