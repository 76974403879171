import { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Drawer, List, Grid, Badge } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    Person, RemoveCircle, Info, Flight, Build, Gavel, School, LiveTv, Security, Public, NewReleases, LocalAtm, Receipt,
    Notifications, EmojiTransportation, Straight, CalendarViewWeek, AccessTime, LocationCity, AdminPanelSettings, Checkroom,
    AirplanemodeInactive, Menu
} from '@mui/icons-material';
import HandymanIcon from '@mui/icons-material/Handyman';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CertificateApi from '../../api/certificates'
import ExtraPaymentsAPI from '../../api/extraPayments'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducer';
import LinkInfo from './LinkInfo';
import mainLogo from '../../assets/logoWhite.png';
import { Link } from "react-router-dom";
import { myPayment } from '../../types/extraPayments'
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 210;

const useStyles = makeStyles(theme => ({
    drawerLogo: {
        width: theme.spacing(18),
        margin: '15px 20px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icons: {
        color: 'red',
        opacity: 0.5
    },
    link: {
        textDecoration: 'none',
    },
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerCloseIcon: {
        color: 'white',
        fontSize: '30px',
        '&:hover': {
            cursor: 'pointer',
        },
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(2)
    },
    drawerPaper: {
        width: drawerWidth,
        overflow: 'hidden'
    },
    updated: {
        color: 'green'
    },
    customScrollbar: {
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555'
        }
    }
}));

type DrawProps = {
    onDrawerToggle: Function
    open: boolean
}

const Draw: FunctionComponent<DrawProps> = ({ onDrawerToggle, open }) => {
    const store = useSelector((state: RootState) => state);
    const user = store.user.me;
    const isMonitorUser = (user.display_name !== undefined) ? user.display_name === "Maintener" : false;
    const routes = isMonitorUser ? [] : (user.user_clearence_groups !== undefined) ? user.user_clearence_groups : [];
    const isExtraPaymentSupervisor = routes.includes("Extra Payment Supervisor");
    const [certificateStatus, setCertificateStatus] = useState('');
    const [supervisorPayments, setSupervisorPayments] = useState<myPayment[]>([]);
    const [activePage, setActivePage] = useState(window.location.href);
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        let routeCount = 0
        routes?.forEach(route => {
            if (route.includes("shop")) {
                routeCount = routeCount + 1;
            }
        })
        if (document.documentElement.clientWidth < 1540) {
            onDrawerToggle();
        }
        if (!isMonitorUser) {
            open = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (user.user_id !== undefined) {
            CertificateApi.GetCertificateStatus(user.user_id).then(res => {
                if (res.status) {
                    setCertificateStatus(res.data.expired_date_status.toString());
                }
            })
        }

        if (isExtraPaymentSupervisor) {
            ExtraPaymentsAPI.GetSupervisorNotifications().then(res => {
                if (res.status === true) {
                    setSupervisorPayments(res.data);
                }
            })
        }
        setActivePage(window.location.href.substring(window.location.href.lastIndexOf('/')));
    }, [certificateStatus, user]);

    const changePage = (route: string) => {
        setActivePage(route);
    }
    const generateLink = (accessGroups: string[], route: string, name: string, icon: ReactElement, extraIcon?: ReactElement, extraIconName?: string) => {
        if (routes.some((r) => accessGroups.includes(r)) || accessGroups.length === 0) {
            const isActive = location.pathname.includes(route);
            return (
                <Link className={classes.link} onClick={() => changePage(route)} to={route}>
                    <LinkInfo 
                        name={name} 
                        icon={icon} 
                        extraIcon={extraIcon} 
                        extraIconName={extraIconName} 
                        customColor={isActive ? 'active' : undefined}
                    />
                </Link>
            )
        }
    }

    return (
        <div>
            <Drawer
                variant="persistent"
                id="drawer"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left">
                <Grid container>
                    <Grid item xs={8}>
                        <img onClick={() => navigate('/hangar-overview')} className={classes.drawerLogo} src={mainLogo} alt="Logo" />
                    </Grid>
                    <Grid item xs={3}>
                        <Menu onClick={() => onDrawerToggle()} id="drawerButton" className={classes.drawerCloseIcon} />
                    </Grid>
                </Grid>
                <Grid className={classes.customScrollbar}>
                    <List>
                        {generateLink(["Developers", "Access Control Admin"], "/access-control", "Access Control", <AdminPanelSettings />)}
                        {generateLink(["Bay Handover View", "Bay Handover Edit"], "/bay-handover", "Bay Handover", <AddLocationAltIcon />)}
                        {generateLink(["Certificates View", "Certificates Create", "Certificates Approver"], "/certificates", "Certificates", <School />)}
                        {generateLink(["Clothes Request", "Clothes Request - View"], "/clothing-request", "Clothes Requests", <Checkroom />)}
                        {generateLink(["Course Managers"], "/courses", "Courses", <School />)}
                        {generateLink(["Extra Payment User", "Extra Payment Supervisor", "Extra Payment Admin"], "/extra-payments", "Extra Payments",
                            <Receipt />, supervisorPayments.length > 0 ? <Badge badgeContent={supervisorPayments.length} color="error"><Notifications color="secondary" /></Badge> : undefined,
                            supervisorPayments.length > 0 ? "Waiting approval" : undefined)}
                        {generateLink(["Hangar Overview"], "/hangar-overview", "Hangar Overview", <Flight />)}
                        {generateLink([], "/my-profile", "My Profile", <Person />,
                            certificateStatus === 'Expiring' ? <Info color="secondary" /> : certificateStatus === 'Expired' ? <RemoveCircle color="error" /> : certificateStatus === 'Updated' ? <NewReleases className={classes.updated} /> : undefined,
                            certificateStatus === 'Expiring' ? 'Certificate is expiring within 1 month' : certificateStatus === 'Expired' ? 'Your certificate has expired' : certificateStatus === 'Updated' ? 'Your certificate has been updated' : undefined)}
                        {generateLink(["Line Planner", "Line Planner View", "Line Supervisor Edit", "Line Supervisor View"], "/line", "Line", <Straight />)}
                        {generateLink(["Lite Edit", "Lite View"], "/lite", "Lite", <Public />)}
                        {generateLink(["Security Edit", "Security View"], "/security", "Security", <Security />)}
                        {generateLink(["Staff Management Edit", "Staff Management View"], "/staff-management", "Staff Management", <LiveTv />)}
                        {generateLink(["Tool Stock View", "Tool Stock"], "/tool-stock", "Tools Stock", <Build />)}
                        {generateLink(["Tool Register"], "/tool-register", "Tools Register", <Gavel />)}
                        {generateLink(["World Tools Edit", "World Tools View"], "/world-tools", "World Tools", <Public />)}
                        {generateLink(["Task Assignment Edit", "Task Assignment View"], "/task-assignment", "Task Assignment", <CalendarViewWeek />)}
                        {generateLink(["Vacation User", "Vacation Admin"], "/vacations", "Vacations", <EmojiTransportation />)}
                        {generateLink(["Warranty Edit", "Warranty View"], "/warranty", "Warranty", <LocalAtm />)}
                        {generateLink(["Time Barcodes Edit", "Time Barcodes View"], "/time-control-barcodes", "Time Barcodes", <AccessTime />)}
                        {generateLink(["Rvk Line Edit", "Rvk Line View"], "/line-rvk", "Rvk Line", <LocationCity />)}
                        {generateLink(["Capability Edit", "Capability View"], "/capability", "Capability", <Person />)}
                        {generateLink(["Mechanic Skills Edit"], "/skills", "Mechanic Info", <HandymanIcon />)}
                        {generateLink(["Maintenance Reports Edit", "Maintenance Reports View"], "/maintenance-reports", "Maintenance Reports", <AirplanemodeInactive />)}
                        {generateLink(["Hangar Plan View", "Hangar Plan Edit"], "/HangarPlan", "Hangar Plan", <HandymanIcon />)}
                    </List>
                </Grid>
            </Drawer>
        </div>
    );
};

export default Draw;