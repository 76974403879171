import { FunctionComponent } from 'react';
import { Typography } from '../../MaterialUiComponents';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Grid } from '@mui/material';
import { BayTailInfo } from '../../types/staffManagement';

const useStyles = makeStyles(theme => ({
    header: {
        padding: '0.2rem', 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '1.75rem',
    }
}));

type BayProps = {
    banner_color: string,
    bay: string,
    children?: React.ReactNode;
    bayTailInfo?: BayTailInfo;
    isbay?: boolean;
}

const CardComponent: FunctionComponent<BayProps> = ({ bay, children, banner_color, isbay, bayTailInfo }) => {

    const classes = useStyles();
    const getWorkPackagesString = (workPackages: { wo_name: string }[]): string => {
        return bayTailInfo?.tailNumber
        ? bayTailInfo.tailNumber + (workPackages?.length ? " | " + workPackages.map(row => row.wo_name).join(' | ') : "")
        : "";
    };

    return (
        <Card style={{ backgroundColor: "white", border: banner_color === 'black' ? '3px solid black' : 'none', borderRadius: '8px'}}>
        <Grid
            style={{backgroundColor: banner_color}}
            className={classes.header}
        >
            <Typography
                textColor={'white'}
                text={`${bay} ${(bayTailInfo !== undefined && isbay) ? ( "    " + getWorkPackagesString(bayTailInfo.workPackages)) : ""}`}
                align='center'
                variant='h4'
            />
        </Grid>
        <CardContent>
            <Grid>{children}</Grid>
        </CardContent>
    </Card>
    
    )
}
export default CardComponent;