import { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Switch, Paper, TextField, Button } from '@mui/material';
import WarrantyPDF from './WarrantyPDF';
import { DatePicker, Dialog, Input, Select, Typography, Tooltip } from '../../MaterialUiComponents';
import IcelandairLogo from '../../assets/icelandairLogo.svg';
import { Close, Comment, AddComment, AttachMoney, AccessTime, Save, Edit, Check, Delete, Upload, Download, Description, Add } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';
import { dictionary, comment, warrantyMxiData, warrantyRequest, WarrantyMaintenerPart, actionItem, pdfObj } from '../../types/warranty';
import { Autocomplete } from '@mui/material';
import WarrantyApi from './../../api/warranty';
import { redirectToMxi } from './../../config/mxiProvider';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import { claimClassificationOptions, claimResultOptions, claimResultRejectedOptions, claimStatusOptions } from '../../properties/warrantiesProperties';
import ProfileImg from '../../components/Images/ProfileImg';
import { DateTime } from 'luxon';

const useStyles = makeStyles(theme => ({
    icelandairLogo: {
        height: '50px',
        bottom: '5px',
        position: 'relative',
    },
    infoTop: {
        marginBottom: theme.spacing(2)
    },
    formContainer: {
        marginTop: theme.spacing(1)
    },
    faultContainer: {
        backgroundColor: theme.palette.action.focus,
        padding: theme.spacing(3),
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    addComment: {
        marginTop: theme.spacing(2)
    },
    commentsContainer: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        width: '100%',
        marginBottom: theme.spacing(3),
    },
    commentIcon: {
        marginRight: theme.spacing(2)
    },
    userPhoto: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: "50%",
        position: "relative",
        top: "10px",
        left: theme.spacing(5),
        marginRight: theme.spacing(2)
    },
    userPhotoActions: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        borderRadius: "50%",
    },
    deleteComment: {
        color: theme.palette.common.white
    },
    timeSeperator: {
        color: 'gray',
        marginTop: theme.spacing(3),
        fontWeight: 'bold',
        textAlign: 'center'
    },
    saveButton: {
        marginLeft: theme.spacing(2),
    },
    figure: {
        margin: theme.spacing(1),
    },
    removePart: {
        position: "absolute",
        right: theme.spacing(5),
    },
    removePartAdjust: {
        position: "absolute",
    },
    savePart: {
        color: theme.palette.success.main
    },
    partInputs: {
        marginTop: theme.spacing(0.5)
    }
}));

type WarrantyProps = {
    cageCodes: dictionary[]
    warrantyId: number | null
    returnToOverview: Function
    setLoading: Function
    loading: boolean
    urlBarcode: string,
    setTabValue: Function,
    refreshOverview: Function,
    allWarranties: warrantyRequest[]
}

// This page contains a single warranty request including fault data. On new request it takes input of fault barcode to fetch fault data for the request
const Warranty: FunctionComponent<WarrantyProps> = ({ cageCodes, warrantyId, returnToOverview, setLoading, loading, urlBarcode, setTabValue, refreshOverview, allWarranties}) => {
    const store = useSelector((state: RootState) => state);
    const [commentDialogOpen, setCommentDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [faultBarcode, setFaultBarcode] = useState<string>("");
    const [faultFetched, setFaultFetched] = useState<boolean>(false);
    const [errorText, setErrorText] = useState("");
    const [selectedClassification, setSelectedClassification] = useState<string>("");
    const [selectedStatus, setSelectedStatus] = useState<string>("In process");
    const [newPart, setNewPart] = useState<boolean>(false);
    const [selectedCageCode, setSelectedCageCode] = useState<dictionary>();
    const [warrantyMxiData, setWarrantyMxiData] = useState(Array<warrantyMxiData>());
    const [warrantyRequest, setWarrantyRequest] = useState<warrantyRequest>();
    const [newWarrantyId, setNewWarrantyId] = useState<number | null>(warrantyId);
    const [comments, setComments] = useState<comment[]>();
    const [reasonForRemoval, setReasonForRemoval] = useState("");
    const [actionItems, setActionItems] = useState<actionItem[]>();
    const [shippingCost, setShippingCost] = useState<string>("");
    const [actualHours, setActualHours] = useState<string>("");
    const [actualMinutes, setActualMinutes] = useState<string>("");
    const [hourlyRate, setHourlyRate] = useState<number>(0);
    const [commentToAdd, setCommentToAdd] = useState<string>("");
    const [createdDate, setCreatedDate] = useState<Date>(new Date());
    const [totalPartAmount, setTotalPartAmount] = useState<string>("");
    const [selectedClaimResult, setSelectedClaimResult] = useState<warrantyRequest["claim_request_type"]>(null);
    const [poNumber, setPoNumber] = useState<string | null>(null);
    const [partToEdit, setPartToEdit] = useState<number>(-1);
    const [saved, setSaved] = useState<boolean>(true);
    const [selectedPartToDelete, setSelectedPartToDelete] = useState<WarrantyMaintenerPart>();
    const [isBeingFetched, setIsBeingFetched] = useState<boolean>(true);
    const [pdfData, setPdfData] = useState<pdfObj[]>([]);
    const [deleteWarrantyRequestDialogOpen, setDeleteWarrantyRequestDialogOpen] = useState<boolean>(false);
    const [deletedReason, setDeletedReason] = useState<string>("");
    const [maintenerParts, setMaintenerParts] = useState<WarrantyMaintenerPart[]>([]);
    const [editPart, setEditPart] = useState<WarrantyMaintenerPart>({} as WarrantyMaintenerPart);

    const searchFaultByBarcode = (barcode: string) => {
        const warrantyAlreadyExists = faultExistsValidation(barcode);
        if (barcode.length > 0 && !warrantyAlreadyExists) {
            setLoading(true);
            WarrantyApi.GetFaultByBarcode(barcode).then(res => {
                if (res.status === true) {
                    setWarrantyRequest(res.data);
                    setWarrantyMxiData(res.mxiData);
                    setReasonForRemoval(res.data.reason_for_removal);
                    setFaultFetched(true);
                    setActionItems(res.actionItems);
                    setActualHours(res.data.actual_manhours_worked_total_hrs);
                    setActualMinutes(res.data.actual_manhours_worked_total_min);
                    let tempPartTotal = 0;
                    res.mxiData.forEach(part => {
                        tempPartTotal = tempPartTotal + part.unit_price
                    });
                    setTotalPartAmount(tempPartTotal.toString());
                } else {
                    setErrorText(res.message);
                }
                setLoading(false);
            });

        }
    }

    const getWarrantyPdfStatus = () => {
        if (warrantyId) {
            //Fetching pdf's when page loads, prepare pdf's download for faster experiance.
            WarrantyApi.DownloadPDF(warrantyId).then(res => {
                if (res.status === true) {
                    setPdfData(res.data);
                }
            });
        }
    }

    useEffect(() => {
        getWarrantyPdfStatus();
        if (newWarrantyId !== null) {
            refresh(newWarrantyId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (urlBarcode.length > 0) {
            setFaultBarcode(urlBarcode);
            searchFaultByBarcode(urlBarcode);
        }
        else {
            setIsBeingFetched(false);
        }
    }, []);

    useEffect(() => {
        setSaved(false);
    }, [createdDate, selectedCageCode, selectedClassification, selectedStatus, selectedClaimResult, shippingCost, actualHours, reasonForRemoval]);

    const closeClaim = () => {
        if (warrantyId === null) {
            setFaultBarcode("");
            setFaultFetched(false);
        }
        else {
            returnToOverview();
        }
    }
    const refresh = (id: number) => {
        setLoading(true);
        WarrantyApi.getWarrantyRequestById(id).then(res => {
            setMaintenerParts(res.maintenerParts);
            setSelectedClaimResult(res.data.claim_request_type);
            setWarrantyRequest(res.data);
            setWarrantyMxiData(res.mxiData);
            setSelectedClassification(res.data.claim_classification_type);
            setNewPart(res.data.is_part_purchased);
            setSelectedStatus(res.data.request_status);
            setSelectedCageCode(cageCodes.find(code => code.id === res.data.cage_code_id));
            setShippingCost(res.data.shipping_claimed_cost.toString());
            setReasonForRemoval(res.data.reason_for_removal);
            setFaultFetched(true);
            setComments(res.comments);
            setActionItems(res.actionItems);
            setHourlyRate(res.data.mechanic_hourly_rate);
            setLoading(false);
            setCreatedDate(res.data.created_date);
            setActualHours(res.data.actual_manhours_worked_total_hrs);
            setActualMinutes(res.data.actual_manhours_worked_total_min);
            setFaultBarcode(res.data.fault_barcode);
            setPoNumber(res.data.po_number);
            let tempPartTotal = 0;
            res.mxiData.forEach(part => {
                tempPartTotal = tempPartTotal + part.unit_price
            });
            setTotalPartAmount(tempPartTotal.toString());
            setSaved(true);
        });
    }

    const createWarranty = () => {
        const warrantyAlreadyExists = faultExistsValidation(faultBarcode);
        if (selectedCageCode !== undefined && hourlyRate !== undefined && selectedClassification !== "" && actualHours !== "" && actualMinutes !== "" && !warrantyAlreadyExists) {
            const isDUmmy = warrantyRequest?.is_dummy ? warrantyRequest?.is_dummy : false;
            WarrantyApi.createWarrantyRequest(store.user.me.user_id, faultBarcode, selectedCageCode.id, selectedStatus, selectedClassification, newPart, +shippingCost, reasonForRemoval, hourlyRate, createdDate, actualHours, actualMinutes, poNumber, isDUmmy, selectedClaimResult).then(res => {
                if (res.status === false) {
                    setErrorText("Database error");
                } else {
                    setSaved(true);
                    setErrorText("");
                    setNewWarrantyId(res.id);
                    refresh(res.id);
                }
            });
        } else {
            setErrorText("Please fill in all fields before creating claim request and make sure to claim the manhours");
        }
    }
    const updateWarranty = () => {
        if (selectedCageCode !== undefined && warrantyRequest !== undefined && newWarrantyId !== null) {
            WarrantyApi.updateWarrantyRequest(newWarrantyId, store.user.me.user_id, faultBarcode, selectedCageCode.id, selectedStatus, selectedClassification, newPart, +shippingCost, reasonForRemoval, createdDate, actualHours, actualMinutes, selectedClaimResult, poNumber, hourlyRate).then(res => {
                if (newWarrantyId !== null) {
                    setSaved(true);
                    refresh(newWarrantyId);
                }
            });
        }
    }
    const onCommentDialogClose = (status: boolean) => {
        if (status === true && warrantyRequest !== undefined) {
            WarrantyApi.AddComment(warrantyRequest.warranty_request_id, store.user.me.user_id, commentToAdd).then(res => {
                refresh(warrantyRequest.warranty_request_id);
            });
        }
        setCommentDialogOpen(false);
        setCommentToAdd("");
    }
    const deleteComment = (commentToDelete: comment) => {
        if (warrantyRequest !== undefined) {
            WarrantyApi.deleteComment(commentToDelete, store.user.me.user_id).then(res => {
                refresh(warrantyRequest.warranty_request_id);
            });
        }
    }

    const onEditPart = (part: WarrantyMaintenerPart, index: number) => {
        setEditPart(part);
        setPartToEdit(index);
    }
    const onSavePart = () => {
        if (editPart.is_new_part !== true) {
            WarrantyApi.updatePartRequest(editPart).then(res => { });
        }
        else {
            WarrantyApi.CreatePartRequest(editPart).then(res => { });
        }
        if (newWarrantyId !== null) {
            refresh(newWarrantyId);
        }
        setPartToEdit(-1);
    }
    const onDeletePart = (part: WarrantyMaintenerPart) => {
        setSelectedPartToDelete(part);
        setDeleteDialogOpen(true);
    }
    const onConfirmDelete = (status: boolean) => {
        if (status === true) {
            if (selectedPartToDelete !== undefined) {
                WarrantyApi.deletePartRequest(selectedPartToDelete.id, store.user.me.user_id).then(res => {
                    if (newWarrantyId !== null) {
                        refresh(newWarrantyId);
                    }
                });
            }
        }
        setDeleteDialogOpen(false);
    }

    const handleFileChange = (e: any) => {
        if (e.target.files[0]) {
            const file: File = e.target.files[0];
            const fileData = new FormData();
            fileData.append("file_name", file.name);
            fileData.append("form_file", file);
            if (warrantyId) {
                WarrantyApi.SavePDF(fileData, warrantyId).then(res => {
                    getWarrantyPdfStatus();
                });
            }
        }
    };

    const onDownloadPDF = () => {
        if (warrantyId) {
            pdfData.forEach(pdf => {
                const linkSource = `data:application/pdf;base64,${pdf.base_64_string}`;
                const downloadLink = document.createElement("a");
                const fileName = pdf.name;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            });
        }
    }

    const onDeleteWarrantyPDF = (pdf: pdfObj) => {
        WarrantyApi.DeletePDF(pdf.pdf_id).then(res => {
            getWarrantyPdfStatus();
        });
    }

    const createDummyWarranty = () => {
        const warrantyAlreadyExists = faultExistsValidation(faultBarcode);
        if(!warrantyAlreadyExists){
            setFaultFetched(true);
            const tempWarranty: warrantyRequest = {
                warranty_request_id: -1,
                warranty_id: -1,
                po_number: "",
                created_date: new Date(),
                created_date_text: "",
                created_by_employee_id: -1,
                created_by_employee_name: "",
                claim_request_type: null,
                last_modified_date: new Date(),
                last_modified_by_employee_id: -1,
                fault_barcode: "",
                cage_code_id: -1,
                cage_code_text: "",
                warranty_status: "",
                request_status: "",
                claim_classification_type: "",
                is_part_purchased: false,
                part_claimed_cost: 0,
                claimed_man_hrs: 0,
                shipping_claimed_cost: 0,
                deleted_reason: "",
                visible: false,
                mxiData: [],
                manhours_claimed_cost: 0,
                actual_manhours_worked_total: 0,
                actual_manhours_worked_total_hrs: 0,
                actual_manhours_worked_total_min: 0,
                actual_manhours_worked_total_text: "",
                actual_manhours_worked_total_cost: 0,
                reason_for_removal: "",
                mechanic_hourly_rate: 0,
                total_claimed_cost: 0,
                mxi_actual_worked_manhours_hrs: 0,
                mxi_actual_worked_manhours_min: 0,
                last_comment: "",
                part_numbers: [],
                tail_number: "",
                is_dummy: true,
                has_attachment: false,
            }
            setWarrantyRequest(tempWarranty);
        }
    }

    const onDeleteWarrantyRequest = () => {
        WarrantyApi.DeleteWarrantyRequest(warrantyId, deletedReason).then(res => {
            if (res.status === true) {
                setTabValue(0);
                refreshOverview();
            }
        });
    }

    const createNewPart = () => {
        const newPart = {
            id: -1,
            warranty_request_id: warrantyId,
            created_date: new Date(),
            created_by_employee_id: -1,
            last_modified_date: new Date(),
            last_modified_by_employee_id: -1,
            deleted_reason: "",
            part_tsn_hours: 0,
            part_tsn_minutes: 0,
            inv_no_id: -1,
            part_name: "",
            part_number: "",
            part_cost_usd: -1,
            purchase_order_no: "",
            part_serial_no: "",
            is_new_part: true,
            visible: true,
            fault_start_date: null,
        } as WarrantyMaintenerPart;
        const allParts = [...maintenerParts, newPart];
        setMaintenerParts(allParts);
    }

    const faultExistsValidation = (barcode: string) =>{
        if (barcode.length > 0) {
            if(allWarranties.find(warranty => warranty.fault_barcode === barcode) !== undefined){
                setErrorText("This fault barcode already exists in the system");
                return true;
            }
            else{
                setErrorText("");
                return false;
            }
        } else {
            setErrorText("Please provide barcode");
            return true;
        }
    }
    
    const classes = useStyles();
    return (
        <Grid sx={{}}>
            {faultFetched === false && loading === false ?
                <Grid container xs={12} sx={{ visibility: isBeingFetched ? "hidden" : "" }}>
                    <Grid item xs={12}>
                        <Input multiline={false} help_text={errorText === "" ? "Type / paste in barcode" : errorText} error={errorText !== ""} label_text={"Fault barcode"} value={faultBarcode} onTextChange={(text: string) => setFaultBarcode(text)} type="text" width={40} />
                    </Grid>
                    <Grid item>
                        <Tooltip title={"Create warranty item from mxi Data"} type='secondary'>
                            <Button id="fetchBtn" variant="contained" color="primary" size="medium" onClick={() => searchFaultByBarcode(faultBarcode)}>Fetch fault</Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={"Create Dummy item"} type='secondary'>
                            <Button id="createBtn" variant="contained" color="primary" size="medium" onClick={() => createDummyWarranty()} style={{ marginLeft: '16px' }}>Create fault</Button>
                        </Tooltip>
                    </Grid>
                    <Typography text={errorText} color='error' margin={[1, 0, 0, 2]} />
                </Grid>
                :
                faultFetched === false && loading === true ?
                    <div></div>
                    :
                    warrantyRequest !== undefined &&
                    <Grid>
                        <Grid className={classes.infoTop}></Grid>
                        <Grid container justifyContent="center" xs={12}>
                            <Grid item xs={7}>
                                <Typography color='primary' variant='h1' text="Warranty Claim" align='left' />
                            </Grid>
                            <Grid item xs={4}>
                                <img src={IcelandairLogo} className={classes.icelandairLogo} alt="Logo" />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => { closeClaim() }} color="default" >
                                    <Close />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container xs={12}>
                            <Grid item>
                                <Tooltip title={`Download to computer this warranty request as pdf`} type='primary' placement="top-start">
                                    <WarrantyPDF mxiData={warrantyMxiData} warrantyRequest={warrantyRequest} actionItems={actionItems} />
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Button startIcon={<Save />} className={classes.saveButton} variant="contained" color={saved ? "primary" : "secondary"} size="medium" onClick={() => { newWarrantyId !== null ? updateWarranty() : createWarranty() }}>Save</Button>
                            </Grid>
                            {(warrantyId !== null && warrantyId !== undefined) &&
                                <Grid item >
                                    <Grid container direction="row">
                                        <Grid item style={{ marginTop: "0px" }}>
                                            <label htmlFor={'fileUploadButton'}>
                                                <Tooltip title={`Upload a pdf to this warranty request`} type='primary' placement="top-start">
                                                    <Button
                                                        startIcon={<Upload />}
                                                        color="primary"
                                                        className={classes.saveButton}
                                                        variant="contained"
                                                        component="span"
                                                    >
                                                        Upload pdf
                                                    </Button>
                                                </Tooltip>
                                            </label>
                                            <input id="fileUploadButton" style={{ display: 'none' }} type="file" onChange={(e: any) => { handleFileChange(e) }} />
                                        </Grid>
                                        {pdfData.length > 0 &&
                                            <Grid item>
                                                <Tooltip title={`Download to computer the pdf's that have been uploaded to this warranty request`} type='primary' placement="top-start">
                                                    <Button startIcon={<Download />} className={classes.saveButton} variant="contained" color={"primary"} size="medium" onClick={() => { onDownloadPDF() }}>Download  PDF</Button>
                                                </Tooltip>
                                            </Grid>
                                        }
                                        <Grid item>
                                            <Tooltip title={`Delete this warranty request`} type='primary' placement="top-start">
                                                <Button startIcon={<Delete />} className={classes.saveButton} variant="contained" color={"primary"} size="medium" onClick={() => { setDeleteWarrantyRequestDialogOpen(true) }}>Delete warranty</Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid container alignItems="flex-start" xs={12}>
                            <Grid item xs={12} lg={6}>
                                <Grid container xs={12} alignItems="flex-start" justifyContent="flex-start" spacing={2} className={classes.formContainer}>
                                    <Grid item xs={12}>
                                        {errorText !== "" && <Typography text={errorText} color='error' />}
                                        <Grid container alignItems="center" xs={12} direction="row">
                                            <Grid item onClick={() => redirectToMxi(faultBarcode)}><Typography cursor="pointer" color="primary" text={faultBarcode} fontWeight="bold" /></Grid>
                                        </Grid>
                                        <Grid container alignItems="center" xs={12} direction="row">
                                            <Grid item> <Typography text="Fix / Reimburse Part" /></Grid>
                                            <Grid item>
                                                <Switch
                                                    checked={newPart}
                                                    onChange={() => { setNewPart(!newPart) }}
                                                    color="primary"
                                                    name="newPart"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography text="New Part Purchased" />
                                            </Grid>
                                        </Grid>
                                        <Grid container alignItems="center" xs={12} direction="row">
                                            <Typography display="inline" fontWeight="bold" text="MXI Manhours" />
                                            <Typography display="inline" text={": " + warrantyRequest.mxi_actual_worked_manhours_hrs + " hrs : " + warrantyRequest.mxi_actual_worked_manhours_min + " min"} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DatePicker type="Date" selected={ DateTime.fromISO(createdDate.toString()).toJSDate()} label="Request created" onDateChange={(date: Date) => setCreatedDate(date)} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Autocomplete
                                            fullWidth
                                            noOptionsText="No Cage Code found"
                                            options={cageCodes}
                                            getOptionLabel={option => option.name}
                                            defaultValue={selectedCageCode}
                                            value={selectedCageCode}
                                            clearOnEscape
                                            autoHighlight
                                            onChange={(e: any, newValue: dictionary | null) => newValue ? setSelectedCageCode(newValue) : {}}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Cage Code" variant="standard" />
                                            )} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select width={100} widthPercentage filter_by_text="Claim Classification" onSelectedItem={(e: any) => setSelectedClassification(e.target.value)} options={Object.values(claimClassificationOptions)} selectedItem={selectedClassification} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select width={100} widthPercentage filter_by_text="Claim Status" onSelectedItem={(e: any) => setSelectedStatus(e.target.value)} options={Object.values(claimStatusOptions)} selectedItem={selectedStatus} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select width={100} widthPercentage filter_by_text="Claim Result" onSelectedItem={(e: any) => setSelectedClaimResult(e.target.value)} options={["Not Claimed", "Rejected"].includes(selectedStatus) ? Object.values(claimResultRejectedOptions) : Object.values(claimResultOptions)} selectedItem={selectedClaimResult} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Input multiline={false} help_text={""} label_text={"Add PO Number"} value={poNumber === null ? "" : poNumber} onTextChange={(text: string) => { setPoNumber(text) }} type="text" width={100} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Input icon={<AttachMoney />} disabled multiline={false} help_text={""} label_text={"Parts total"} value={totalPartAmount} onTextChange={(text: string) => { setTotalPartAmount(text) }} type="number" width={100} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Input icon={<AttachMoney />} multiline={false} help_text={"Shipping Cost"} label_text={"Shipping Cost"} value={shippingCost} onTextChange={(text: string) => { setShippingCost(text) }} type="number" width={100} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Input icon={<AttachMoney />} multiline={false} help_text={"Hourly Rate"} label_text={"Hourly Rate"} value={hourlyRate} onTextChange={(text: number) => { setHourlyRate(text) }} type="text" width={100} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}>
                                                <Input icon={<AccessTime />} textAlign="center" multiline={false} help_text={"Hours"} label_text={"Manhours"} value={actualHours} onTextChange={(text: string) => { setActualHours(text) }} type="number" width={100} />
                                            </Grid>
                                            <Grid className={classes.timeSeperator} item xs={2}>:</Grid>
                                            <Grid item xs={4}>
                                                <Input textAlign="center" multiline={false} help_text={"Minutes"} label_text={""} value={actualMinutes} onTextChange={(text: string) => { setActualMinutes(text) }} type="number" width={100} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Input multiline={true} help_text={"This is fetched from the Fault data originally but can be modified for the claim"} label_text={"Reason for Removal"} value={reasonForRemoval} onTextChange={(text: string) => { setReasonForRemoval(text) }} type="number" width={100} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography text="Actions taken" variant="caption" />
                                        {actionItems?.map(action => (
                                            <Paper variant="outlined" elevation={0}>
                                                <Grid alignItems="center" container xs={12}>
                                                    <Grid item xs={2} md={1}>
                                                        <figure className={classes.figure}>
                                                            <img src={action.created_by_user_image} className={classes.userPhotoActions} alt="user_image" />
                                                            <figcaption>{action.created_by_name.split(" ")[0]}</figcaption>
                                                        </figure>
                                                    </Grid>
                                                    <Grid item xs={10} md={11}>
                                                        <Typography display="inline" text={action.action_text} variant="body1" />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        ))}
                                    </Grid>
                                    {pdfData.length > 0 &&
                                        <Grid>
                                            {pdfData.map(pdf => (
                                                <IconButton color="primary" onClick={() => onDeleteWarrantyPDF(pdf)}>
                                                    <Tooltip title={`Delete ${pdf.name}`} type='primary' placement="top-start">
                                                        <FolderDeleteIcon sx={{ width: '100px', height: '100px' }} />
                                                    </Tooltip>
                                                </IconButton>
                                            ))
                                            }
                                        </Grid>
                                    }
                                    {(warrantyId !== null || newWarrantyId !== null) &&
                                        <Grid container justifyContent="center" className={classes.addComment}>
                                            <IconButton color="primary" onClick={() => { setCommentDialogOpen(true) }}><AddComment fontSize="large" /></IconButton>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        {comments?.map((comment, index) => (
                                            <Paper variant="elevation" elevation={5} className={classes.commentsContainer}>
                                                <Grid container xs={12}>
                                                    <Grid item xs={11}>
                                                        <Comment className={classes.commentIcon} />
                                                        <Typography display="inline" text={comment.created_date_text} variant="caption" />
                                                        <ProfileImg imgKey={index} userName={store.employees.all_employees.find(s => s.Id === comment.created_by_employee_id)?.userName.split('@')[0]} />
                                                        <Typography display="inline" text={comment.comment} variant="body1" margin={[0, 0, 0, 5]} />
                                                    </Grid>
                                                    {
                                                        comment.system_generated === false &&
                                                        <Grid item xs={1}>
                                                            <IconButton onClick={() => deleteComment(comment)} className={classes.deleteComment}><Close /></IconButton>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Paper>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Dialog
                                visible={commentDialogOpen}
                                max_width="md"
                                title="Please type in your comment"
                                show_options={true}
                                strict={false}
                                onClose={(status: boolean) => { onCommentDialogClose(status) }}
                                context={
                                    <Grid container justifyContent="center">
                                        <Input multiline={true} help_text={"Type in your comment"} label_text={"Comment to be added"} value={commentToAdd} onTextChange={(text: string) => { setCommentToAdd(text) }} type="text" width={80} />
                                    </Grid>
                                } />
                            <Dialog
                                visible={deleteDialogOpen}
                                max_width="sm"
                                title=""
                                show_options={true}
                                strict={false}
                                onClose={(status: boolean) => onConfirmDelete(status)}
                                context={
                                    <Grid container justifyContent="center">
                                        <Typography text="Are you sure you want to remove this part from the Warranty Claim Request?" variant="h2" color="textPrimary" align="center" />
                                    </Grid>
                                } />
                            {/* Right side space */}
                            {warrantyMxiData.length !== undefined &&
                                <Grid container xs={12} lg={6}>
                                    <Grid item xs={6}><Typography margin={[0, 0, 2, 0]} align="center" variant="h2" text={"Aircraft: " + warrantyMxiData[0]?.aircraft !== undefined ? warrantyMxiData[0]?.aircraft : ""} /></Grid>
                                    <Grid item xs={6}><Typography align="center" variant="h2" text={"Manufactured: " + warrantyMxiData[0]?.aircraft_manufactured_date_text !== undefined ? warrantyMxiData[0]?.aircraft_manufactured_date_text : ""} /></Grid>
                                    {maintenerParts.map((part, index) => (
                                        <Paper variant="elevation" className={classes.faultContainer} elevation={5}>
                                            <IconButton onClick={() => onDeletePart(part)} className={classes.removePart}> <Delete color="error" className={classes.removePartAdjust} /> </IconButton>
                                            <Grid container spacing={1} xs={12}>
                                                <Grid item xs={12} >
                                                    <Input
                                                        icon={<Description />}
                                                        disabled={partToEdit === index ? false : true}
                                                        multiline={false}
                                                        help_text={""}
                                                        label_text={"Part description"}
                                                        value={partToEdit === index ? editPart.part_name : part.part_name}
                                                        onTextChange={(text: string) => setEditPart({ ...editPart, part_name: text })}
                                                        type="text"
                                                        width={95}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12}>
                                                        <Input
                                                            icon={<Description />}
                                                            disabled={partToEdit === index ? false : true}
                                                            multiline={false}
                                                            help_text={""}
                                                            label_text={"Part number"}
                                                            value={partToEdit === index ? editPart.part_number : part.part_number}
                                                            onTextChange={(text: string) => setEditPart({ ...editPart, part_number: text })}
                                                            type="text"
                                                            width={95}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                icon={<Description />}
                                                                disabled={partToEdit === index ? false : true}
                                                                multiline={false}
                                                                help_text={""}
                                                                label_text={"Serial number"}
                                                                value={partToEdit === index ? editPart.part_serial_no : part.part_serial_no}
                                                                onTextChange={(text: string) => setEditPart({ ...editPart, part_serial_no: text })}
                                                                type="text"
                                                                width={95}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Input
                                                                icon={<Description />}
                                                                disabled={partToEdit === index ? false : true}
                                                                multiline={false}
                                                                help_text={""}
                                                                label_text={"Purchase Order"}
                                                                value={partToEdit === index ? editPart.purchase_order_no : part.purchase_order_no}
                                                                onTextChange={(text: string) => setEditPart({ ...editPart, purchase_order_no: text })}
                                                                type="text"
                                                                width={95}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid item xs={12}>
                                                        <DatePicker
                                                            disabled={index === partToEdit ? false : true}
                                                            type="Date"
                                                            selected={(editPart.fault_start_date !== null && editPart.fault_start_date !== undefined) ? editPart.fault_start_date : null}
                                                            label={`Removal date`}
                                                            onDateChange={(date: Date) => setEditPart({ ...editPart, fault_start_date: date })}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography display="inline" text="Time since new: " variant="body1" fontWeight="bold" />
                                                        <Typography
                                                            display="inline"
                                                            text={(part.part_tsn_hours !== null ? part.part_tsn_hours : 0) + " hrs : " + (part.part_tsn_minutes !== null ? part.part_tsn_minutes : 0) + " min"}
                                                            variant="body1"
                                                        />
                                                    </Grid>
                                                    <Grid container className={classes.partInputs} xs={12}>
                                                        <Grid item xs={3}>
                                                            <Input
                                                                disabled={index === partToEdit ? false : true}
                                                                icon={<AttachMoney />}
                                                                multiline={false}
                                                                help_text={""}
                                                                label_text={"Part cost"}
                                                                value={index === partToEdit ? editPart.part_cost_usd : part.part_cost_usd}
                                                                onTextChange={(text: string) => { setEditPart({ ...editPart, part_cost_usd: parseInt(text) }) }}
                                                                type="number"
                                                                width={100}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    {
                                                        index !== partToEdit ?
                                                            <IconButton size="medium" onClick={() => onEditPart(part, index)}>
                                                                <Edit color="primary" />
                                                            </IconButton>
                                                            :
                                                            <IconButton size="medium" onClick={() => { onSavePart() }}>
                                                                <Check className={classes.savePart} />
                                                            </IconButton>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    ))}
                                    <Grid container justifyContent="center" >
                                        <IconButton color="primary" onClick={() => { createNewPart() }}><Add fontSize="large" /></IconButton>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>}
            <Dialog
                visible={deleteWarrantyRequestDialogOpen}
                max_width="sm"
                title="Are you sure you want to delete this warranty request?"
                show_options={true}
                strict={false}
                onClose={(status: boolean) => { status ? onDeleteWarrantyRequest() : setDeleteWarrantyRequestDialogOpen(false) }}
                context={
                    <Grid container justifyContent="center">
                        <Input multiline={false} help_text={""} label_text={"deleted reason"} value={deletedReason} onTextChange={(text: string) => setDeletedReason(text)} type="text" width={40} />
                    </Grid>
                }
            />
        </Grid >
    );
};
export default Warranty;