import { useState} from 'react';
import Grid from '@mui/material/Grid2';
import MobiscrollCalendar from '../../components/Calendar/MobiscrollCalendar';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { makeStyles } from '@mui/styles';
import { EventInfo } from '../../types/mobiScroll';
import WorkPackageDetailsDialog from './WorkPackageDetailsDialog';

const useStyles = makeStyles({
    //Hides the all day element in the calendar
    hiddenMobiscrollElement: {
        '& .mbsc-schedule-event-range.mbsc-ios': {
            display: 'none !important',
            
        }
    },
});

const LongTermHangarPlanTab = ({ data, hangars, onRefresh }: { data: EventInfo[]; hangars: string[] ;  onRefresh: () => void }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<MbscCalendarEvent>();
    const classes = useStyles();

    const onEventClicked = (event: MbscCalendarEvent) => {
        setSelectedEvent(event.event);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        onRefresh();
        setSelectedEvent(undefined); // Reset selectedEvent on close
    };
    
    return (
        <Grid className={classes.hiddenMobiscrollElement}>
            <MobiscrollCalendar 
                calendarTasks={data} 
                resources={hangars} 
                onEventClick={onEventClicked} 
                isShortTermView={false}
            />

            {dialogOpen && selectedEvent && (
                <WorkPackageDetailsDialog
                    dialogOpen={dialogOpen}
                    onClose={handleDialogClose}
                    selectedEventIn={selectedEvent}
                />
            )}
            
        </Grid>
    );
}
export default LongTermHangarPlanTab;