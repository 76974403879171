import { DataActions } from "../types/actions";
import {AppData} from "../types/data";

// using this as generic data reducer, for various types of application data
const initialState : { data: AppData } = { data : {drawerOpen:false} };

export const dataReducer = (state = { ...initialState }, action: DataActions) => {
	switch (action.type) {
		case 'ADD_DATA':
			return { ...state, data: action.data };
		default:
			return state;
	}
}