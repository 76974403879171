import { FunctionComponent, useState } from 'react';
import { Grid } from '@mui/material';
import { Dialog, Input, Snackbar } from '../../MaterialUiComponents';
import { EmployeeSearch } from '../../components';
import SkillApi from '../../api/skillsApi';
import UserApi from '../../api/userApi';
import { RootState } from '../../reducer';
import { makeStyles } from '@mui/styles';
import { User } from '../../types/user';
import { useDispatch, useSelector } from 'react-redux';

type AbbriviationsProps = {
    loading: boolean,
    setLoading: Function,
}

const useStyles = makeStyles((theme) => ({
    modalContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    evenRows: {
        backgroundColor: theme.palette.action.hover
    },
    delete: {
        color: 'red'
    },
    searchContainer: {
        marginLeft: "15%",
        marginBottom: '28px',
    },
    verticalDivider: {
        height: '100%',
        width: '2px',  // Width of the divider
        background: theme.palette.divider,  // You can customize the color here
    },
}));

type SnackbarMessage = {
    message: string,
    status: boolean
}

const Abbreviations: FunctionComponent<AbbriviationsProps> = ({ loading, setLoading }) => {

    const dispatch = useDispatch();
    const store = useSelector((state: RootState) => state);
    const [selectedEmployee, setSelectedEmployee] = useState<User | undefined>(undefined);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [abbreviation, setAbbreviation] = useState<string>("");
    const [snackbarMessage, setSnackbarMessage] = useState<SnackbarMessage>({message: "", status: false});

    const classes = useStyles();

    const onSelectedEmployee = (employeeId: number) => {
        if(employeeId >=1){
            const user = store.employees.all_employees.find((user: User) => user.Id === employeeId);
            setSelectedEmployee(user !== undefined ? user : undefined);
            if(user !== undefined){
                SkillApi.GetAbbreviationByEmployeeId(user.Id).then(res => {
                    setAbbreviation(res.data);
                });
            }
            setIsDialogOpen(true);
        }
    }

    const onCloseEditAbbriviationDialog = (status: boolean) => {
        if (status === true && abbreviation !== "") {
            const duplicateEmployee = store.employees.all_employees.find((user: User) => user.abbreviation === abbreviation);
            if(duplicateEmployee !== undefined){
                setSnackbarMessage({message: "Abbreviation already exists", status: false});
                setIsDialogOpen(true);
            }
            else{
                setLoading(true);
                if (selectedEmployee?.Id !== undefined) {
                    SkillApi.EditAbbreviation(selectedEmployee.Id, abbreviation).then(res => {
                        if (res.status === false) {
                            setSnackbarMessage({message: res.message, status: false});
                            setIsDialogOpen(true);
                        } else {
                            setSnackbarMessage({message: "Successfully updated abbreviation", status: true});
                            setLoading(false);
                            setIsDialogOpen(false);
                            setAbbreviation("");
                            
                            // After saving a new abbreviation, we need to refresh the list of employees to reflect the changes.
                            UserApi.getAllUsers().then(response => {
                                dispatch({ type: "GET_ALL_EMPLOYEES", all_employees: response });
                                setLoading(false);
                            })
                        }
                    });
                } else {
                    setIsDialogOpen(false); 
                    setAbbreviation("");
                }
            }
        } else {
            setIsDialogOpen(false); 
            setAbbreviation("");
        }
    }

    const handleInputChange = (e: string) => {
        const value = e;

        // Filter out any non-letter characters
        const filteredValue = value.replace(/[^A-Za-z]/g, '');
        // and ensure it's no longer than 4 characters
        setAbbreviation(filteredValue.slice(0, 4));
    };

    return (
        <Grid >
            <Grid className={classes.searchContainer}>
                <Grid>
                    <EmployeeSearch
                        inputWidth={75}
                        place_holder={"Write employee name"}
                        onSelectedEemployee={(employeeId: number) => { onSelectedEmployee(employeeId) }}
                        customCloseFunction={() => { }}
                    />
                </Grid>
            </Grid>
            <Dialog
                visible={isDialogOpen}
                max_width={"md"}
                title={`Type in the abbreviation for ${selectedEmployee?.name}`}
                context={
                    <Grid>
                        <Input
                            multiline={false}
                            label_text="Abbreviation"
                            help_text="Please provide an abbreviation for the employee."
                            type="text"
                            value={abbreviation}
                            width={100}
                            onTextChange={(value: string) => handleInputChange(value)}
                        />
                    </Grid>
                }
                onClose={(status: boolean) => { onCloseEditAbbriviationDialog(status) }}
                strict={false}
                show_options={true}
            />
            <Snackbar onSnackBarClose={() => setSnackbarMessage({message: "", status: true})} open={snackbarMessage.message.length > 0} message={snackbarMessage.message} autoHide={true} severity={snackbarMessage.status === false ? 'error' : 'success'} horizontalPosition='right' />
        </Grid >
        
    )
}
export default Abbreviations;