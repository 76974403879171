import { FunctionComponent, useEffect, useState } from 'react';
import { Input, Typography, Tabs } from '../../MaterialUiComponents';
import { Grid, Switch } from '@mui/material';
import { PhoneAndroidOutlined, Title } from '@mui/icons-material';
import { User } from '../../types/user';
import { LoanedToolsForMe } from '../../types/tool';
import { CertificateData } from '../../types/certificates'
import MyTools from './../../Pages/MyProfile/MyTools';
import MyCertificates from './../../Pages/MyProfile/MyCertificates';
import ToolApi from './../../api/tools';
import certificatesApi from './../../api/certificates';
import StaffManagementApi from './../../api/staffmanagement';
import SkillsTab from './SkillsTab';
import SkillsApi from '../../api/skillsApi';
import { AssignedMechanicSkill } from '../../types/skills';
import { StaffMtpEmployee } from '../../types/staffManagement';
import getUserName from '../../utils/staffmanagement';
import ProfileImg from '../../components/Images/ProfileImg';
import { makeStyles } from '@mui/styles';
import StaffApi from './../../api/staffmanagement';

type AboutEmployeeProps = {
    user: User | StaffMtpEmployee | null,
    tabNames: string[],
    refresh: Function,
}

const useStyles = makeStyles(theme => {
    return {
        MItem: {
            display: 'inline-block',
            marginBottom: '16px',
            width: '100%',
        },
        flexContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }
    }
});

const AboutEmployee: FunctionComponent<AboutEmployeeProps> = ({ user, tabNames, refresh }) => {
    const [tools, setTools] = useState(Array<LoanedToolsForMe>());
    const [myFilteredTools, setFilteredTools] = useState<LoanedToolsForMe[]>([]);
    const [validCert, setValidCert] = useState<CertificateData>();
    const [tabValue, setTabValue] = useState<number>(0);
    const [employeeWorkstation, setEmployeeWorkstation] = useState<string>("");
    const [assignedMechanicSkills, setAssignedMechanicSkills] = useState<AssignedMechanicSkill[]>([]);
    const [teamLeader, setTeamLeader] = useState<boolean>(user !== null && "is_team_leader" in user && user.is_team_leader);
    const classes = useStyles();

    useEffect(() => {
        const userId = user!.user_id;
        StaffManagementApi.GetEmployeeLocationByEmployeeID(user!.user_id).then(res => {
            if (res.status === true) {
                const data = res.data;
                setEmployeeWorkstation(data.bay + " in " + data.department_code);
            } else {
                setEmployeeWorkstation("");
            }
        })
        ToolApi.GetToolsByEmployeeId(userId).then(res => {
            if (res.status === true) {
                setTools(res.data);
                const filteredTools = res.data.filter(tool => {
                    return tool.returned_date === null;
                });
                setFilteredTools(filteredTools);
            }
        })
        certificatesApi.GetCertificateByEmployeeId(userId).then(res => {
            setValidCert(res.data);
        })
        SkillsApi.GetMechanicSkillsById(userId).then(res => {
            setAssignedMechanicSkills(res.data);
        });
    }, []);

    let userName = "";
    if (user !== null) {
        userName = getUserName(user);
    }

    const EmployeeInfo = () => {
        return (
            <Grid>
                <Grid>
                    <Typography variant='h4' align='center' text={employeeWorkstation} />
                </Grid>
                <br />
                <Grid container>
                    <Grid xs={4}>
                        <ProfileImg userName={userName} imgSize={"large"} />
                    </Grid>
                    <Grid xs={8}>
                        <Input
                            icon={<PhoneAndroidOutlined />}
                            disabled={true} multiline={false} help_text={""}
                            label_text={"Mobile"} value={user!.mobile}
                            type="text" width={95} onTextChange={() => { }}
                        />
                        <Input
                            icon={<Title />}
                            disabled={true} multiline={false} help_text={""}
                            label_text={"Job Title"} value={user!.job_title}
                            type="text" width={95} onTextChange={() => { }}
                        />
                        <Input
                            icon={<Title />}
                            disabled={true} multiline={false} help_text={""}
                            label_text={"CRS Number"} value={user!.crs_number}
                            type="text" width={95} onTextChange={() => { }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const onToggleTeamLeader = () => {
        if (user !== null && "department_code_name" in user && typeof user.department_code_name === 'string') {
            if (teamLeader === false) {
                StaffApi.AssignLeaderRole(user.user_id, user.department_code_name).then(res => {
                    if (res.status === true) {
                        setTeamLeader(!teamLeader);
                        refresh();
                    }
                });
            }
            else {
                StaffApi.RemoveLeaderRole(user.user_id, user.department_code_name).then(res => {
                    if (res.status === true) {
                        setTeamLeader(!teamLeader);
                        refresh();
                    }
                });
            }
        }
    }

    return (
        <Grid>
            <Tabs
                tabNames={tabNames}
                onChangeTabValue={(tabValue: number) => setTabValue(tabValue)}
                tabValue={tabValue}
            />
            {tabValue === 0 && <EmployeeInfo />}
            {(tabValue === 1 && tools !== undefined) && <MyTools tools={tools} filteredTools={myFilteredTools} />}
            {tabValue === 2 && <MyCertificates validCert={validCert} />}
            {tabValue === 3 && <SkillsTab assignedSkills={assignedMechanicSkills} />}
            {tabValue === 4 &&
                <Grid className={classes.flexContainer}>
                    <Typography align='center' variant='h3' text={"Not team leader"} margin={[0, 0, 0.5, 0]} />
                    <Switch
                        checked={teamLeader}
                        onChange={() => onToggleTeamLeader()}
                        color="secondary"
                        name="checkedB"
                    />
                    <Typography align='center' variant='h3' text={"Team leader"} margin={[0, 0, 0.5, 0]} />
                </Grid>
            }
        </Grid>
    )
}
export default AboutEmployee;