import { combineReducers } from 'redux';
import { toolsReducer } from './features/tools';
import { userReducer } from './features/user';
import { employeesReducer } from './features/employees';
import { liteReducer } from './features/Lite';
import { lineReducer } from './features/Line';
import {imageReducer} from './features/image';
import { dataReducer } from './features/data';

const rootReducer = combineReducers({
  tools: toolsReducer,
  employees: employeesReducer,
  user: userReducer,
  line: lineReducer,
  lite: liteReducer,
  image: imageReducer,
  data: dataReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer