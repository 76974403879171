import { settings } from './../config';
import { getToken } from './../global/TokenValidator';
import { MxiTask } from './../types/tasks';
import { HangarPlanPackage } from '../types/hangarPlan';
class HangarPlanApi {
  
    GetTasksByWorkpackageBarcode = async (woBarcode: string): Promise<{ status: boolean, message: string, data: MxiTask[] }> => {
        return fetch(`${settings.HOST}/HangarPlan/GetTasksByWorkpackageBarcode/${woBarcode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
                'apikey': settings.KONG_KEY,
            },
        }).then(x => {
            return x.json()
        })
    }

    GetHangarPlanPackages = async (from: Date, to: Date, status: Array<string>, locations: Array<string>
        ): Promise<{ status: boolean; message: string; data: Array<HangarPlanPackage> }> => {
        return fetch(`${settings.HOST}/HangarPlan/GetHangarPlanPackages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': settings.KONG_KEY,
                'token': await getToken(),
            },
            body: JSON.stringify({
                "from": from,
                "to": to,
                "status": status,
                "locations": locations,
            })
        }).then(x => {
            return x.json()
        })
    }

}
export default new HangarPlanApi();