import { FunctionComponent, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import React from 'react';
import { Avatar, Badge, IconButton, List, ListItem, ListItemAvatar, ListItemText, TableCell, TableRow } from '@mui/material';
import { MbscCalendarEvent } from '@mobiscroll/react';
import { Typography, Table, Dialog, LinearProgress, Tooltip, SelectMultiple } from '../../MaterialUiComponents';
import { MxiTask } from '../../types/tasks';
import { redirectToMxi } from '../../config/mxiProvider';
import crewPlanApi from '../../api/crewPlan';
import PartDetails from '../../components/Parts/PartDetails';
import { EmployeeSearch, Collection } from '../../components';
import { formatFloatNumberToHoursMinString } from '../../utils/dateUtils';
import { makeStyles } from '@mui/styles';
import { EventInfo } from '../../types/mobiScroll';
import { hangarPlanLineOptions } from '../../properties/lineProperties';
import hangarPlanApi from '../../api/hangarPlanApi';
import { Link, ShoppingCart, Visibility } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducer';

type Props = {
    dialogOpen: boolean,
    selectedEventIn: MbscCalendarEvent,
    onClose: () => void;
}

const WorkPackageDetailsDialog : FunctionComponent<Props> = React.memo(({ dialogOpen, selectedEventIn, onClose }) => {
    
    const store = useSelector((state: RootState) => state);
    const [partDialogOpen, setPartDialogOpen] = useState(false);
    const [LabourDialogOpen, setLabourDialogOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<MxiTask>();
    const isHangarPlanAdmin = store.user.me.user_clearence_groups.includes("Hangar Plan Edit");
    const [tasksInWorkpackage, setTasksInWorkpackage] = useState(Array<MxiTask>);
    const [selectedEvent, setSelectedEvent] = useState<MbscCalendarEvent>(selectedEventIn);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        setSelectedEvent(selectedEventIn);
        refreshWorkpackage(selectedEvent.id!.toString());
        setLoading(true);
    }, [selectedEventIn]);
    
    const ReturnStatusColor = (status: string, unassigned: boolean | undefined) => {

        if (unassigned === true) {
            return "#FF0000"
        }
        switch (status) {
            case ("COMPLETE"):
                return "#497523";
            case ("ACTV"):
                return "#F5B53F";
            case ("PAUSE"):
                return "";
            case ("COMMIT"):
                return "#E4B7B7";
            default:
                return "#506E9F";
        }
    }

    const getStatusBorderProperties = (status: string) => {
        if (status === "PAUSE") {
            return {
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: "black",
            };
        }

        return {};
    };

    const onSelectedEmployee = (employeeId: number) => {
        crewPlanApi.ChangeLabour(selectedTask!.barcode, employeeId, selectedTask!.wo_barcode).then(res => {
            refreshWorkpackage(selectedEvent.id!.toString());
        })
    }

    const refreshWorkpackage = (woBarcode: string) => {
        setLoading(true);
        hangarPlanApi.GetTasksByWorkpackageBarcode(woBarcode).then(res => {
            if (res.status === true) {
                setTasksInWorkpackage(res.data);

                if (selectedTask) {
                    setSelectedTask(res.data.find(s => s.barcode === selectedTask.barcode));
                }
                setLoading(false);
            }
        });
    }

    const onLabourColumnClicked = (row: MxiTask) => {
        if (isHangarPlanAdmin) {
            setLabourDialogOpen(true); setSelectedTask(row);
        }
    }

    function onCloseDialog() {
        onClose();
    }

    const generateTableBody = (row: MxiTask) => {
        return (
            <TableRow>
                <TableCell>{row.mxi_task_id}</TableCell>
                <TableCell >
                    <Grid container>
                        <IconButton onClick={() => redirectToMxi(row.barcode)} style={{ backgroundColor: ReturnStatusColor(row.status, row.unassigned), borderRadius: "10px", ...getStatusBorderProperties(row.status) }}><Link color="primary" /></IconButton>
                        {row.part_amounts > 0 && <Grid style={{ margin: 'auto' }} onClick={() => { setPartDialogOpen(true); setSelectedTask(row); }}>
                            <Tooltip title={`${row.part_amounts} parts`} type='primary' placement="top-start">
                                <Badge badgeContent={row.part_amounts} color="primary" >
                                    <ShoppingCart style={{ cursor: 'pointer', margin: 'auto' }} color={row.part_not_available ? 'error' : 'primary'} />
                                </Badge>
                            </Tooltip>
                        </Grid>
                        }
                        {row.need_cert && <Grid style={{ margin: 'auto' }}> <Tooltip title={`Certification required`} type='primary' placement="top-start"><Visibility /></Tooltip></Grid>}
                    </Grid>
                </TableCell>
                <TableCell style={{ width: "30%", textDecoration: row.unassigned === true ? "line-through" : undefined }}>{row.task_name}</TableCell>
                <TableCell>{row.task_start_time}</TableCell>
                <TableCell>{row.task_stop_time}</TableCell>
                <TableCell>{row.action_items_counter}</TableCell>
                <TableCell onClick={() => { onLabourColumnClicked(row) }}>
                    <List>
                        {row.assigned_employees.map(emp => (
                            <ListItem>
                                <ListItemAvatar >
                                    <Avatar
                                        style={{ height: "20px", width: "20px" }}
                                        src={emp.employee_image} />
                                </ListItemAvatar>
                                <ListItemText>{emp.employee_name}</ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Grid>
           
            <Dialog
                fullScreen={true}
                show_options={false}
                strict={false}
                isSlide={true}
                max_width={'xl'}
                onClose={() => onCloseDialog()}
                title={""}
                visible={dialogOpen}
                context={
                    <Grid>
                        
                        <Typography text={selectedEvent?.title} align='center' variant='h2' />
                        <Grid container justifyContent={'center'}>
                            <span style={{ display: 'inline' }}>
                                {/*  This code was outcommented in DDINT-3756, will be inroduced later
                                
                                {(selectedEvent?.mt_man_hours > 0 || selectedEvent?.mxi_man_hours > 0) && <span>Man hours: </span>}
                                {selectedEvent?.mt_man_hours > 0 && <span>MT: {formatFloatNumberToHoursMinString(selectedEvent?.mt_man_hours ?? -1)}</span>}
                                {selectedEvent?.mt_man_hours > 0 && selectedEvent?.mxi_man_hours > 0 && ', '}
                                {selectedEvent?.mxi_man_hours > 0 && <span>MXi: {formatFloatNumberToHoursMinString(selectedEvent?.mxi_man_hours ?? -1)}</span>} */}

                                {selectedEvent?.mt_man_hours > 0 && <span>Man hours: {formatFloatNumberToHoursMinString(selectedEvent?.mt_man_hours ?? -1)}</span>}

                            </span>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <Typography text={`${selectedEvent?.start_date_text} - ${selectedEvent?.end_date_text}`} />
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <Typography text={selectedEvent?.wo_status} fontWeight='bold' margin={[1, 0, 0, 0]} />
                            <IconButton onClick={() => redirectToMxi(selectedEvent?.id)}><Link color="primary" /></IconButton>
                        </Grid>
                        <Grid container justifyContent={'center'} >
                            <Typography text={`ACTV (${tasksInWorkpackage.filter(s => s.status === "ACTV").length})`} align='center' variant='h5' textColor='#F5B53F' />
                            <Typography text={`In Work (${tasksInWorkpackage.filter(s => s.status === "IN WORK").length})`} align='center' variant='h5' textColor='#506E9F' margin={[0, 4, 0, 4]} />
                            <Typography text={`Pause (${tasksInWorkpackage.filter(s => s.status === "PAUSE").length})`} align='center' variant='h5' textColor='black' margin={[0, 4, 0, 0]} />
                            <Typography text={`Complete (${tasksInWorkpackage.filter(s => s.status === "COMPLETE").length})`} align='center' variant='h5' textColor='#497523' margin={[0, 4, 0, 0]} />
                            <Typography text={`Unassign (${tasksInWorkpackage.filter(s => s.unassigned === true).length})`} align='center' variant='h5' textColor='#FF0000' margin={[0, 4, 0, 0]} />
                        </Grid>
                        <Grid>
                            
                            <Table
                                headers={["", "Task Name", "Start", "End", "Action", "Labour"]}
                                rows={tasksInWorkpackage}
                                generateBody={(row: MxiTask) => generateTableBody(row)}
                                defaultRowsPerPage={50}
                                pagination={true}
                            />
                            {loading === true &&
                            <Grid>
                                <br />
                                <LinearProgress />
                            </Grid>
                            }
                        </Grid>
                    </Grid>
                }
            />
            {partDialogOpen === true && <Dialog
                fullScreen={false}
                show_options={false}
                strict={false}
                max_width={'lg'}
                onClose={(status: any) => setPartDialogOpen(false)}
                title={selectedTask!.task_name}
                visible={partDialogOpen}
                context={
                    <PartDetails taskBarcode={selectedTask!.barcode} />
                }
            />}
            {LabourDialogOpen === true && <Dialog
                fullScreen={false}
                show_options={false}
                strict={false}
                max_width={'lg'}
                onClose={(status: any) => setLabourDialogOpen(false)}
                title={selectedTask!.task_name}
                visible={LabourDialogOpen}
                context={
                    <Grid>
                        <EmployeeSearch place_holder='Search for employee' onSelectedEemployee={(id: number) => onSelectedEmployee(id)} />
                        <Collection edit={true} background={false} collectionList={selectedTask!.assigned_employees} title="Assigned Employees" nameProperty="employee_name" idProperty="employee_id" imageProperty="user_image" removeItem={(itemId: number) => onSelectedEmployee(itemId)} />
                    </Grid>
                }
            />}
        </Grid>
    );

});
export default WorkPackageDetailsDialog;
