import { actionItem } from '../types/warranty';
import { settings } from './../config';
import { getToken } from './../global/TokenValidator';
import { Event, LabourAssignedByTaskBarcode, MxiTask } from './../types/tasks';
class CrewPlan {
    ChangeLabour = async (taskBarcode: string, employeeId: number, woBarcode: string): Promise<{ status: boolean, message: string, data: LabourAssignedByTaskBarcode[] }> => {
        return fetch(`${settings.HOST}/crewplan/ChangeLabour`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
                'apikey': settings.KONG_KEY,
            },
            body: JSON.stringify(
                {
                    "task_barcode": taskBarcode,
                    "employee_id": employeeId,
                    "wo_barcode": woBarcode
                }
            )
        }).then(x => {
            return x.json()
        })
    }
    GetLabourAssignedByTaskBarcode = async (taskBarcode: string): Promise<{ status: boolean, message: string, data: LabourAssignedByTaskBarcode[] }> => {
        return fetch(`${settings.HOST}/crewplan/GetLabourAssignedByTaskBarcode/${taskBarcode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
                'apikey': settings.KONG_KEY,
            },
        }).then(x => {
            return x.json()
        })
    }
    GetTasksAssignedByMyCrew = async (): Promise<{ status: boolean, message: string, data: Event[] }> => {
        return fetch(`${settings.HOST}/crewplan/GetTasksAssignedByMyCrew`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
                'apikey': settings.KONG_KEY,
            },
        }).then(x => {
            return x.json()
        })
    }
    GetTasksAssignedEmployeeId = async (employeeId: number): Promise<{ status: boolean, message: string, data: Event[] }> => {
        return fetch(`${settings.HOST}/crewplan/GetTasksAssignedEmployeeId/${employeeId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
                'apikey': settings.KONG_KEY,
            },
        }).then(x => {
            return x.json()
        })
    }
    GetTasksAssignedToMe = async (): Promise<{ status: boolean, message: string, data: Event[] }> => {
        return fetch(`${settings.HOST}/crewplan/GetTasksAssignedToMe`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
                'apikey': settings.KONG_KEY,
            },
        }).then(x => {
            return x.json()
        })
    }
    GetMechanicTaskNotesByBarcode = async (taskBarcode: string): Promise<{ status: boolean, message: string, data: actionItem[] }> => {
        return fetch(`${settings.HOST}/crewplan/GetMechanicTaskNotesByBarcode/${taskBarcode}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': await getToken(),
                'apikey': settings.KONG_KEY,
            },
        }).then(x => {
            return x.json()
        })
    }
}
const CrewPlanApi = new CrewPlan();
export default CrewPlanApi;